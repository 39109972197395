import React, { useState, useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import Spinner from "../../component/Spinner/Spinner";
import { MessageOutlined } from "@ant-design/icons";
import "../../assets/css/index.css";
import { Button, Col, Row } from "antd";
import { api } from "../../api/axios";
import Swal from "sweetalert2";

const Font = Quill.import("attributors/style/font");
Font.whitelist = ["Arial", "Tahoma", "Courier", "Times-New-Roman", "Prompt"];
Quill.register(Font, true);

const Size = Quill.import("attributors/style/size");
Size.whitelist = ["10px", "12px", "14px", "16px", "18px", "24px", "32px", "36px", "48px"];
Quill.register(Size, true);

const Consent = () => {
    const [isSpinner, setIsSpinner] = useState(false);
    const [contentPrivacy, setContentPrivacy] = useState("");
    const [contentUnder10Notification, setContentUnder10Notification] = useState("");
    const modules = {
        toolbar: [
            [{ font: Font.whitelist }],
            [{ size: Size.whitelist }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            ["blockquote", "code-block"],
            ["link", "image", "video"],
            ["clean"],
        ],
    };

    const formats = [
        "font", "size", "header", "bold", "italic", "underline", "strike",
        "color", "background", "align", "list", "bullet", "indent",
        "blockquote", "code-block", "link", "image", "video"
    ];

    const handleSubmitPrivacy = (submitType) => {
        if (submitType === "CREATE" && cleanHtml(contentPrivacy) === "") {
            showToast("error", "กรุณากรอก ข้อความแจ้งนโยบายความเป็นส่วนตัว Privacy Policy");
            return;
        }
        insertOrUpdate("PRIVACY", contentPrivacy, submitType)
    }

    const insertOrUpdate = async (consentType, content, submitType) => {
        setIsSpinner(true);

        const json = createConsentPayload(consentType, content);

        try {
            const response = await api.post("Consent/consent", json, {
                headers: getAuthHeaders(),
            });

            handleResponse(response.data, consentType, submitType);
        } catch (error) {
            console.error("Error saving consent:", error);
            showToast("error", "พบปัญหาในบันทึก กรุณาลองใหม่หรือแจ้งเจ้าหน้าที่");
        } finally {
            setIsSpinner(false);
        }
    };

    const createConsentPayload = (consentType, content) => ({
        consentType,
        consentMessage: content,
        status: "Y",
        note: "",
        log: "",
        userCode: localStorage.getItem("username"),
    });

    const getAuthHeaders = () => ({
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-type": "application/json; charset=UTF-8",
    });

    const handleResponse = (data, consentType, submitType) => {
        if (data.success) {
            showToast("success", getSuccessMessage(consentType, submitType));
        } else {
            showToast("error", "พบปัญหาในบันทึก กรุณาลองใหม่หรือแจ้งเจ้าหน้าที่");
        }
    };

    const showToast = (icon, title) => {
        Toast.fire({ icon, title });
    };

    const getSuccessMessage = (consentType, submitType) =>
        `${submitType === "CREATE" ? 'บันทึก' : 'เคลียร์'} ${consentType === "PRIVACY" ? "ข้อความแจ้งนโยบายความเป็นส่วนตัว Privacy Policy" : "ข้อความแจ้งเตือนสมาชิกที่อายุต่ำกว่า 10 ปี"} เรียบร้อยแล้ว`;


    const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 1000,
        width: '300px'
    });

    const handleSubmitUnder10Notification = (submitType) => {
        if (submitType === "CREATE" && cleanHtml(contentUnder10Notification) === "") {
            showToast("error", "กรุณากรอก ข้อความแจ้งเตือนสมาชิกที่อายุต่ำกว่า 10 ปี");
            return;
        }

        insertOrUpdate("UNDER_10_NOTIFICATION", contentUnder10Notification, submitType)
    }

    useEffect(() => {
        fetchConsents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchConsents = async () => {
        setIsSpinner(true);
        const consentTypes = ["PRIVACY", "UNDER_10_NOTIFICATION"];

        try {
            const responses = await Promise.all(
                consentTypes.map(fetchConsentData)
            );

            responses.forEach(updateConsentState);
        } catch (error) {
            console.error("Error fetching consents:", error);
        } finally {
            setIsSpinner(false);
        }
    };

    const fetchConsentData = (consentType) => {
        return api.get(`Consent/consent?consentType=${consentType}&status=Y`, {
            headers: getAuthHeaders(),
        });
    };

    const updateConsentState = (response) => {
        const data = response.data;
        if (data.success) {
            const { consentType, consentMessage } = data.data;

            switch (consentType) {
                case "PRIVACY":
                    setContentPrivacy(consentMessage);
                    break;
                case "UNDER_10_NOTIFICATION":
                    setContentUnder10Notification(consentMessage);
                    break;
                default:
                    console.warn(`Unknown consent type: ${consentType}`);
            }
        }
    };

    const cleanHtml = (html) => {
        return html === "<p><br></p>" ? "" : html;
    };

    return (
        <>
            <Spinner visible={isSpinner} />
            <MessageOutlined /> จัดการข้อมูลแจ้งเตือน
            <hr />
            <Row style={{ marginBottom: 16 }} justify="center">
                <Col xs={24} md={24} lg={24}>
                    <h2 style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "#2C3E50",
                        textAlign: "left",
                        marginBottom: "16px",
                        borderBottom: "2px solid #3498db",
                        paddingBottom: "8px"
                    }}>
                        ข้อความแจ้งนโยบายความเป็นส่วนตัว <span style={{ color: "#3498db" }}>Privacy Policy</span>
                    </h2>

                    <ReactQuill
                        style={{ marginBottom: 16 }}
                        value={contentPrivacy}
                        onChange={setContentPrivacy}
                        modules={modules}
                        formats={formats}
                        theme="snow"
                        className="custom-quill"
                    />
                </Col>
                <Col xs={24} md={24} lg={24} style={{ textAlign: "right" }}>
                    <Button
                        type="primary"
                        style={{ marginRight: 16 }}
                        onClick={() => handleSubmitPrivacy('CREATE')}
                    >
                        บันทึก
                    </Button>

                    <Button
                        type="danger"
                        onClick={() => {
                            setContentPrivacy("");
                            handleSubmitPrivacy('DELETE');
                        }}
                    >
                        เคลียร์
                    </Button>
                </Col>
            </Row>
            <hr />
            <Row
                style={{ marginBottom: 16, display: "flex", flexDirection: "column", gap: 16 }}
                justify="center"
            >
                <Col xs={24} md={24} lg={24}>
                    <h2 style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "#2C3E50",
                        textAlign: "left",
                        marginBottom: "16px",
                        borderBottom: "2px solid #3498db",
                        paddingBottom: "8px"
                    }}>
                        ข้อความแจ้งเตือนสมาชิก <span style={{ color: "#3498db" }}>ที่อายุต่ำกว่า 10 ปี</span>
                    </h2>
                    <ReactQuill
                        style={{ marginBottom: 16, minHeight: "150px" }}
                        value={contentUnder10Notification}
                        onChange={setContentUnder10Notification}
                        modules={modules}
                        formats={formats}
                        theme="snow"
                        className="custom-quill"
                    />
                </Col>
                <Col xs={24} md={24} lg={24} style={{ textAlign: "right", marginTop: 8 }}>
                    <Button
                        type="primary"
                        style={{ marginRight: 16 }}
                        onClick={() => handleSubmitUnder10Notification('CREATE')}
                    >
                        บันทึก
                    </Button>
                    <Button
                        type="danger"
                        onClick={() => {
                            setContentUnder10Notification("");
                            handleSubmitUnder10Notification('DELETE');
                        }}
                    >
                        เคลียร์
                    </Button>
                </Col>
            </Row>

        </>
    );
};

export default Consent;
