import React, { useState } from 'react'
import {
  Button,
  Row,
  Col,
  Modal,
} from "antd";
import parse from 'html-react-parser'; // ✅ ใช้ default import

export default function ModalConsent({ isModalConsent, handleOk, handleCancel, consentTypes, consentMessage }) {
  const [IsModalConfirmVisible, setIsModalConfirmVisible] = useState(false)

  function handleConfirmCancel() {
    setIsModalConfirmVisible(false);
  }

  return (
    <div>
      <Modal
        title=""
        visible={isModalConsent}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        // closable={null}
        // style={
        //   consentTypes === 'PRIVACY' ? { top: 8 } : {}
        // }
        style={{ padding: 8 }}
        centered
        width={650}
        maskClosable={false}
      >
        <div>
          <div className="custom-quill-content">
            {parse(consentMessage ?? "")}
          </div>
          {consentTypes === 'PRIVACY' ?
            <>
              <div style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  style={{ marginRight: 10, border: "1px solid #1890ff" }}
                  className="buttom-form"
                  onClick={() => setIsModalConfirmVisible(true)}
                  ghost
                >
                  ไม่ยอมรับ
                </Button>

                <Button
                  type="primary"
                  className="buttom-form"
                  onClick={handleOk}
                >
                  ยอมรับ
                </Button>
              </div>
            </>

            : null}
        </div>
      </Modal >

      {/* popup ไม่ยอมรับ pdpa */}
      <Modal
        title={null}
        visible={IsModalConfirmVisible}
        footer={null}
        // closable={null}
        style={{ top: 8 }}
        closable={false}
        width={350}
      >
        <div>


          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div style={{ textAlign: "center" }}>
                <h2>คุณจะพลาดโอกาสในการได้รับสิทธิพิเศษของสมาชิก</h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div style={{ textAlign: "center" }}>
                <p>คุณยืนยันจะไม่ยอมรับ?</p>
              </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginBottom: 10 }}>
              <div style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  className="buttom-form"
                  onClick={handleConfirmCancel}
                >
                  แก้ไข
                </Button>
              </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ paddingLeft: 10 }}>
              <div style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  style={{ marginRight: 10, border: "1px solid #1890ff" }}
                  className="buttom-form"
                  onClick={() => { setIsModalConfirmVisible(false); handleCancel(); }}
                  ghost
                >
                  ยืนยัน
                </Button>
              </div>
            </Col>

          </Row>

        </div>
      </Modal>
    </div >



  )
}
