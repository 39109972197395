import React, { useState, useEffect } from "react";
import { Input, Button, Row, Col } from "antd";
import { SearchOutlined, PlusSquareOutlined } from '@ant-design/icons';
import Spinner from "../../component/Spinner/Spinner";
import FromRegister from "./component/FromRegister";
import { api } from "../../api/axios";
import Swal from "sweetalert2";
import DDL_Brand from "../../component/Dropdown/DDL_Brand";

export default function RegisterMember() {
  const def_brand = "IC000";

  const [spinding, setSpinding] = useState(false);
  const [mobile, setMobile] = useState("");
  const [already, setAlready] = useState(false);
  const [profile, setProfile] = useState({});
  const [brand, setBrand] = useState(localStorage.getItem("brand") == null ? localStorage.getItem("brand") : def_brand);
  const [action, setAction] = useState("");
  const [admin, setAdmin] = useState((localStorage.getItem("groupCode") == "000" || localStorage.getItem("groupCode") == "100"));
  const [mstatus, setMstatus] = useState("");

  useEffect(() => {
    let action = localStorage.getItem("action");
    if (action == "confirm") {
      setBrand(localStorage.getItem("brand_code"));
      setMobile(localStorage.getItem("mobile"));
      setMstatus(localStorage.getItem("mstatus"));
      setTimeout(() => {
        document.getElementsByClassName("ant-btn ant-btn-primary")[0].click();
      }, 100);
    }

    return () => {
      //localStorage.removeItem("mobile");
      //localStorage.removeItem("action");
      localStorage.removeItem("referenceCode");
      localStorage.removeItem("mstatus");
    }
  }, []);

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 1000,
    width: "300px",
  });

  const onSearchMember = () => {
    setSpinding(true);
    const json = {
      Mobile: mobile,
      brand_code: admin ? brand : localStorage.getItem("brand"),
    };
    api
      .post("api/Management/GetMemberByMobile", json, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((response) => {
        let data = response.data;

        if (data.success) {
          if (localStorage.getItem("action") === "confirm") {
            setAction("edit");
            setProfile(data.data);
            setMstatus(data.data.mstatus_code);
            //brand
            setAlready(true);
            setSpinding(false);
          } else {
            localStorage.setItem("action", "search");
            localStorage.setItem("mobile", mobile);
            localStorage.setItem("member_code", "");
            document.getElementsByClassName("ant-menu-item")[0].click();
          }
        } else {
          setAction("register");
          setProfile({
            fname: "",
            lname: "",
            email: "",
            mobile,
            brand_code: admin ? brand : localStorage.getItem("brand"),
            sex: "N",
            birthdate: undefined,
            h_no: "",
            street: "",
            province_code: "",
            district_code: "",
            subdistrict_code: "",
            zip_code: "",
            member_code: "",
            consent_status: false,
            consent_date: "",
            reg_from: "WEB",
            x_status: "Y",
            sent_status: "Y",
            sent_date: "",
          });
          setAlready(true);
          setSpinding(false);
          Toast.fire({
            icon: "warning",
            title: "ไม่พบสมาชิกที่ค้นหา สามารถทำการลงทะเบียนใหม่ได้",
          });
        }


      })
      .catch((err) => {
        if (err.response.status === 401) {
          window.location = `/#/logout`
        }
        setSpinding(false);
      });
  };

  const onClearSearchMember = () => {
    setBrand(def_brand);
    setMobile("");
    setAlready(false);
    setProfile({});
    document.getElementsByClassName("ant-menu-item")[0].click();
  };

  const onChangeMobile = (e) => {
    if (e.target.value.length < 11) {
      setMobile(e.target.value);
    }
  };

  const onSpinding = (visible) => {
    setSpinding(visible);
  };

  const onSelectBrand = (e) => {
    setBrand(e);
  };

  const onClearMember = () => {
    setBrand(def_brand);
    setMobile("");
    setAlready(false);
    setProfile({});
  };

  const marginBottom = { marginBottom: 16 };
  const btnSearch = <Button type="primary" disabled={mobile.length !== 10 || !brand} onClick={onSearchMember}><SearchOutlined /> ค้นหา </Button>;

  const styleHide1 = { display: admin ? "-webkit-box" : "none" };
  const styleHide2 = { display: admin ? "none" : "-webkit-box" };

  return (
    <div>
      <Spinner visible={spinding} />
      <PlusSquareOutlined /> สร้างข้อมูลสมาชิก
      <hr />
      <Row gutter={16} justify="center" style={marginBottom}>
        <Col xs={0} md={4}></Col>
        <Col xs={24} md={8}>
          <label>เบอร์โทรศัพท์</label>
          <Input
            placeholder="เบอร์โทรศัพท์"
            type="number"
            className="mobile-input"
            value={mobile}
            onChange={onChangeMobile}
          />
        </Col>
        <Col xs={24} md={8} style={styleHide1}>
          <label>แบรนด์</label>
          <DDL_Brand
            placeholder={"แบรนด์"}
            onChange={onSelectBrand}
            type={"member"}
            value={brand}
            optionAll={false}
          />
        </Col>

        <Col xs={24} md={8} style={styleHide2}>
          <br />
          {btnSearch}
        </Col>

        <Col xs={0} md={4}></Col>
      </Row>
      <Row gutter={16} justify="center" style={styleHide1}>
        <Col xs={0} md={4}></Col>
        <Col xs={16} style={{ textAlign: "right" }}>
          {btnSearch}
        </Col>
        <Col xs={0} md={4}></Col>
      </Row>
      <hr />
      {already ? (
        <Row gutter={16} justify="center" style={marginBottom}>
          <Col xs={0} md={4}></Col>
          <Col xs={24} md={16}>
            <FromRegister
              onSpinding={onSpinding}
              profile={profile}
              onAction={action}
              onStatus={mstatus}
              _mobile={mobile}
              _brand={brand}
              onSearchMember={onSearchMember}
              onClearSearchMember={onClearSearchMember}
              onClearMember={onClearMember}
            />
          </Col>
          <Col xs={0} md={4}></Col>
        </Row>
      ) : null}
    </div>
  );
}