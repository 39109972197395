import React, { useState, useEffect } from 'react'
import {
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Modal,
  Radio,
  Select,
} from "antd";
import PDPA from "../PDPA/PDPA";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { api } from "../../api/axios";
import parse from 'html-react-parser'; // ✅ ใช้ default import
import Spinner from "../../component/Spinner/Spinner";

export default function Modal_PDPA({ isModalVisible, handleOk, handleCancel }) {

  const [IsModalConfirmVisible, setIsModalConfirmVisible] = useState(false)
  const [content, setContent] = useState("");
  const [isSpinner, setIsSpinner] = useState(false);
  function showConfirm() {
    setIsModalConfirmVisible(true);
  }

  function handleConfirmOk() {
    setIsModalConfirmVisible(false);
  }

  function handleConfirmCancel() {
    setIsModalConfirmVisible(false);
  }


  useEffect(() => {
    if (isModalVisible) {
      fetchConsents('PRIVACY');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible]);

  const fetchConsents = async (consentType) => {
    setIsSpinner(true);
    try {
      const response = await fetchConsentData(consentType)
      const data = response.data;
      if (data.success) {
        const { consentMessage } = data.data;
        setContent(consentMessage);
      }
    } catch (error) {
      console.error("Error fetching consents:", error);
    } finally {
      setIsSpinner(false);
    }
  };

  const fetchConsentData = (consentType) => {
    return api.get(`Consent/consent?consentType=${consentType}&status=Y`, {
      headers: getAuthHeaders(),
    });
  };

  const getAuthHeaders = () => ({
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    "Content-type": "application/json; charset=UTF-8",
  });

  return (
    <div>
      <Spinner visible={isSpinner} />
      <Modal
        title=""
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleOk}
        footer={null}
        // closable={null}
        style={{ top: 8 }}
        width={650}
      >
        <div>
          {/* <PDPA /> */}
          <div className="custom-quill-content">
            {parse(content)}
          </div>
          <div style={{ textAlign: "center" }}>
            <Button
              type="primary"
              style={{ marginRight: 10, border: "1px solid #1890ff" }}
              className="buttom-form"
              onClick={showConfirm}
              ghost
            >
              ไม่ยอมรับ
            </Button>

            <Button
              type="primary"
              className="buttom-form"
              onClick={handleOk}
            >
              ยอมรับ
            </Button>
          </div>
        </div>
      </Modal>

      {/* popup ไม่ยอมรับ pdpa */}
      <Modal
        title={null}
        visible={IsModalConfirmVisible}
        footer={null}
        // closable={null}
        style={{ top: 8 }}
        closable={false}
        width={350}
      >
        <div>


          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div style={{ textAlign: "center" }}>
                <h2>คุณจะพลาดโอกาสในการได้รับสิทธิพิเศษของสมาชิก</h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div style={{ textAlign: "center" }}>
                <p>คุณยืนยันจะไม่ยอมรับ?</p>
              </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginBottom: 10 }}>
              <div style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  className="buttom-form"
                  onClick={handleConfirmCancel}
                >
                  แก้ไข
                </Button>
              </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ paddingLeft: 10 }}>
              <div style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  style={{ marginRight: 10, border: "1px solid #1890ff" }}
                  className="buttom-form"
                  onClick={() => { setIsModalConfirmVisible(false); handleCancel(); }}
                  ghost
                >
                  ยืนยัน
                </Button>
              </div>
            </Col>

          </Row>

        </div>
      </Modal>
    </div>



  )
}
